/* You can add global styles to this file, and also import other style files */
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
@import 'https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.6.1/fullcalendar.min.css';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~nvd3/build/nv.d3.css';
@import '~angular-calendar/css/angular-calendar.css';
@import 'assets/css/default/app.min.css';
@import '~animate.css/animate.min';
@import "~@ng-select/ng-select/themes/default.theme.css";

.panel-iatuch>.panel-heading{background:#3A92AB;color:#fff}

/* Add application styles & imports to this file! */
.modal-holder{
    animation-name: example;
    animation-duration: 0.3s;
  }
  
  @keyframes example {
    0%   {transform: scale(0.5)}
    100% {transform: scale(1)}
  }

  


  .modal-out{

    animation-name: holderout;
    animation-duration: 0.3s;
  }
  
  @keyframes holderout {
    0%   {transform: scale(0.5)}
    100% {transform: scale(0.2)}
  }


  /******* Custom Scroll Bar ********/

    /* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}